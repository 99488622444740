<template>
  <span>
    <span>{{ emoji }}</span>
  </span>
</template>

<script>
export default {
  name: 'SurveyEmoti',
  props: ['score', 'feedback'],
  computed: {
    emoji() {
      const emojis = ['😡', '🙁', '😐', '🙂', '😍']
      return emojis[this.score - 1]
    },
  },
}
</script>
