<template>
  <multiselect
    :style="{ width: optInValue && optInValue.length ? 'auto' : '120px' }"
    selectLabel
    :hideSelected="true"
    :close-on-select="true"
    v-model="optInValue"
    :multiple="false"
    @input="updateOptIns"
    :searchable="false"
    :showLabels="false"
    :options="getOptIns"
    :custom-label="optInLabel"
    track-by="title"
  >
    <template slot="placeholder">
      <span class="text-secondary"> <i class="fas fa-bullhorn"></i> All </span>
    </template>
    <template slot="option" slot-scope="props">
      <div>
        <span class="text-capitalize">{{ props.option.title }}</span>
      </div>
    </template>
  </multiselect>
</template>

<script>
import { getOptIns } from '@/lib/optIn'
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'
const CustomerModule = createNamespacedHelpers('customer')

export default {
  name: 'CustomerOptInFilter',
  components: {
    Multiselect,
  },
  data: () => ({
    getOptIns: getOptIns,
    optInValue: '',
  }),
  computed: {
    ...CustomerModule.mapState(['listFilters']),
  },
  methods: {
    updateOptIns(optInValue) {
      this.$emit('updateOptIns', optInValue)
    },
    optInLabel({ title }) {
      return `${title}`
    },
  },
}
</script>

<style lang="scss">
.multiselect__tag span {
  text-transform: capitalize;
}
</style>
