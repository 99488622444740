<template>
  <div v-if="activeCompany && initialized">
    <div class="card-header" v-if="showStringFilter">
      <CustomerStringFilter :isSearching="isSearching" v-on:updateSearch="filterByString" />
    </div>
    <div class="card-header">
      <div class="row">
        <div class="col-12 mb-0">
          <div class="mt-3 mb-2" v-if="showCustomerCount">
            <b-skeleton
              v-if="isSearching"
              animation="fade"
              width="90px"
              height="22px"
              class="mt-3 mb-2"
            />
            <template v-else>
              <animated-number
                :value="count"
                :formatValue="parseInt"
                :duration="500"
                class="font-weight-bold customer-count"
              />
              <span class="customer-text">
                <span v-if="count !== totalCustomerCount"> of {{ totalCustomerCount }}</span>
                total customers
              </span>
            </template>
          </div>
          <div class="mb-3 clearfix">
            <div class="dropdown d-inline-block mr-4 mb-2 pt--2 filter-container date-filter">
              <h6 class="header-pretitle mt-3">Date Added</h6>
              <CustomerDateFilter v-on:updateDateSettings="filterByDate" />
            </div>
            <div
              v-if="userActiveCompanyLocations.length > 1"
              class="dropdown d-inline-block mr-4 mb-2 filter-container"
            >
              <h6 class="header-pretitle mt-3">Location</h6>
              <CustomerGroupingFilter :forCampaign="campaignSource" @update="groupingUpdate" />
            </div>
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Behavior</h6>
              <CustomerActionFilter
                v-on:updateActions="filterByAction"
                :campaignSource="campaignSource"
              />
            </div>
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Rating</h6>
              <CustomerRatingFilter v-on:updateRatings="filterByRating" />
            </div>
            <div class="d-inline-block mb-2 mr-4 filter-container" v-if="!requireMarketingOptIn">
              <h6 class="header-pretitle mt-3">marketing opt-in</h6>
              <CustomerOptInFilter v-on:updateOptIns="filterByOptIn" />
            </div>
            <div class="d-inline-block mb-2 mr-4 filter-container">
              <h6 class="header-pretitle mt-3">Tags</h6>
              <MultiSelect
                taggable
                multiple
                hideCaret
                reposition
                hideSelected
                label="name"
                track-by="_id"
                v-model="tagsFilters"
                :searchable="false"
                class="tag-filters"
                id="customer-filters"
                :options="tagOptions"
                :show-labels="false"
                openDirection="below"
                tag-placeholder="Add"
                :showNoResults="false"
                :internal-search="true"
                ><template #placeholder>
                  <span class="text-secondary">
                    <span class="fas fa-hashtag"></span> All tags
                  </span>
                </template></MultiSelect
              >
            </div>
            <div
              class="d-inline-block mb-2 mr-4 filter-container"
              v-if="activeCompany.customQuestionsEnabled"
            >
              <h6 class="header-pretitle mt-3">Question Answers</h6>
              <CQAnswerFilter @updateCQAnswers="filterByCQAnswer" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters, mapActions } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const CustomerModule = createNamespacedHelpers('customer')
const CompanyModule = createNamespacedHelpers('company')
const TagModule = createNamespacedHelpers('tag')
const GroupModule = createNamespacedHelpers('group')
const DataCoreModule = createNamespacedHelpers('dataCore')

import AnimatedNumber from 'animated-number-vue'
import CustomerRatingFilter from '@/components/Modules/Customer/CustomerFilters/CustomerRatingFilter'
import CustomerLocationFilter from '@/components/Modules/Customer/CustomerFilters/CustomerLocationFilter'
import CustomerDateFilter from '@/components/Modules/Customer/CustomerFilters/CustomerDateFilter'
import CustomerStringFilter from '@/components/Modules/Customer/CustomerFilters/CustomerStringFilter'
import CustomerActionFilter from '@/components/Modules/Customer/CustomerFilters/CustomerActionFilter/CustomerActionFilter'
import CustomerOptInFilter from '@/components/Modules/Customer/CustomerFilters/CustomerOptInFilter'
import CustomerGroupingFilter from '@/components/Modules/Customer/CustomerFilters/CustomerGroupingFilter'
import CQAnswerFilter from '@/components/Filters/CQAnswerFilter'
import MultiSelect from '@/components/Common/MultiSelect'
export const EVENT_LOADED_CUSTOMERS = 'loaded-customers'

export default {
  name: 'CustomersTableFilters',
  data: () => ({
    queryPending: false,
    tagOptions: [],
    tagsFilters: [],
    filteredGroups: null,
    filteredGroupMembers: null,
    filteredLocations: null,
    filteredDisabledLocations: null,
    initialized: false,
  }),
  props: {
    campaignSource: {
      type: Boolean,
      default: false,
    },
    showCustomerCount: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: Number,
      default: 50,
    },
    showStringFilter: {
      type: Boolean,
      default: true,
    },
    requireMarketingOptIn: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomerRatingFilter,
    CustomerLocationFilter,
    CustomerDateFilter,
    CustomerStringFilter,
    CustomerActionFilter,
    CustomerOptInFilter,
    MultiSelect,
    AnimatedNumber,
    CQAnswerFilter,
    CustomerGroupingFilter,
  },
  async mounted() {
    await this.fetchGroups({
      memberType: 'location',
      companyIds: this.selectActiveCompanyIds,
    })
    this.initialized = true
    this.$emit('filtersInitialized')
  },
  computed: {
    ...UserModule.mapState(['onlineUser']),
    ...CustomerModule.mapState(['listFilters', 'isSearching', 'count', 'totalCustomerCount']),
    ...mapGetters('location', ['userActiveCompanyLocations']),
    ...CompanyModule.mapState(['activeCompany']),
    ...TagModule.mapGetters(['companyTags']),
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
  },
  methods: {
    ...GroupModule.mapActions(['selectGroupLocationIds', 'fetchGroups']),
    ...mapActions('location', ['getUserLocationsByActiveCompany']),
    ...TagModule.mapActions(['getTags']),
    ...CustomerModule.mapMutations(['setListFilters', 'setSkipCount', 'setLimit']),
    ...CustomerModule.mapActions(['queryCustomers', 'queryCustomerCount']),

    async groupingUpdate(val) {
      this.filteredGroups = val && val.groupIds && val.groupIds.length ? val.groupIds : null
      this.filteredGroupMembers =
        val && val.memberIds && val.memberIds.length ? val.memberIds : null
      this.filteredLocations =
        val && val.nonGroupMemberIds && val.nonGroupMemberIds.length ? val.nonGroupMemberIds : null
      this.filteredDisabledLocations =
        val && val.disabledNonGroupMemberIds && val.disabledNonGroupMemberIds.length
          ? val.disabledNonGroupMemberIds
          : null

      let locationsToFilter = [
        ...new Set([
          ...(this.filteredLocations || []),
          ...(this.filteredDisabledLocations || []),
          ...(this.filteredGroupMembers || []),
        ]),
      ]

      this.applyFilters({
        ...this.listFilters,
        locations: locationsToFilter.length
          ? locationsToFilter
          : this.filteredGroups?.length
          ? await this.selectGroupLocationIds(this.filteredGroups)
          : [],
      })
    },
    filterByDate(dateSettings = {}) {
      this.applyFilters({ ...this.listFilters, dateSettings })
    },
    filterByRating(ratings) {
      this.applyFilters({
        ...this.listFilters,
        surveyRatings: ratings.map((rating) => rating.score),
      })
    },
    filterByOptIn(optInValue) {
      if ('Opted In' === optInValue.title) {
        this.applyFilters({
          ...this.listFilters,
          marketingOptIn: [this.activeCompany._id],
          notMarketingOptIn: null,
        })
      } else if ('Not Opted In' === optInValue.title) {
        this.applyFilters({
          ...this.listFilters,
          notMarketingOptIn: [this.activeCompany._id],
          marketingOptIn: null,
        })
      } else {
        this.applyFilters({
          ...this.listFilters,
          notMarketingOptIn: null,
          marketingOptIn: null,
        })
      }
    },
    filterByString(search) {
      this.applyFilters({
        ...this.listFilters,
        search,
      })
    },
    filterByAction(actions) {
      this.applyFilters({
        ...this.listFilters,
        actions,
      })
    },
    filterByCQAnswer(answerData) {
      this.applyFilters({ ...this.listFilters, cqAnswerData: answerData })
    },
    applyFilters(filters) {
      if (!filters.marketingOptIn && this.requireMarketingOptIn) {
        filters.marketingOptIn = [this.activeCompany._id]
      }

      this.setSkipCount(0)
      this.setListFilters(filters)
      // Only query once per render cycle.
      if (!this.queryPending) {
        this.$nextTick(async () => {
          try {
            await Promise.all([this.queryCustomers({}), this.getTags(), this.queryCustomerCount()])
            this.$emit(EVENT_LOADED_CUSTOMERS)
          } finally {
            this.queryPending = false
          }
        })
      }
      this.queryPending = true
    },
  },
  watch: {
    activeCompany: {
      immediate: true,
      async handler(activeCompany) {
        await Promise.all([
          this.fetchGroups({
            memberType: 'location',
            companyIds: this.selectActiveCompanyIds,
          }),
          this.getUserLocationsByActiveCompany(),
        ])

        const filters = {
          ...this.listFilters,
          locations: this.userActiveCompanyLocations.map((location) => location._id),
        }

        this.applyFilters(filters)

        if (this.listFilters.tags) {
          this.tagsFilters = this.listFilters.tags
        }
      },
    },
    limit: {
      handler(is) {
        this.setLimit(is || 50)
      },
      immediate: true,
    },
    companyTags: {
      immediate: true,
      handler(companyTags) {
        this.tagOptions = companyTags
      },
    },
    tagsFilters(tags) {
      const tagActions = tags.map((t) => ({
        id: t._id || t.id,
        name: t.name,
      }))

      this.applyFilters({
        ...this.listFilters,
        tags: tagActions,
      })
    },
  },
}
</script>

<style scoped lang="scss">
.date-filter {
  top: 0px;
}
.filter-container {
  height: 80px;
  // overflow: hidden;
  float: left;
  // margin: 0px !important;
  padding: 0px !important;
}
.tag-filters {
  width: auto;
  min-width: 120px;
}
.customer-count {
  color: #6e84a3;
}
.customer-text {
  color: #95aac9;
}
@media (max-width: 991.98px) {
  .filter-container {
    // margin-right: 0rem !important;
    display: block !important;
    clear: both;
  }
}
</style>
