<template>
  <ul class="list-group">
    <li class="list-group-item">
      <div class="row align-items-center">
        <i class="fe fe-chevron-left btn-back" @click="selectItem()"></i>
        <h6 class="header-pretitle mb-0">Choose a {{ type }}</h6>
      </div>
    </li>
    <div style="max-height: 270px; overflow-y: auto">
      <li class="list-group-item" @click="selectItem(null)">Any {{ type }}</li>
      <li
        v-for="item in items"
        :key="item._id"
        class="list-group-item"
        @click="selectItem(item._id)"
      >
        {{ item.nickname || item.title }}
      </li>
    </div>
  </ul>
</template>

<script>
export default {
  name: 'CustomerActionFilterItemList',
  props: ['type', 'items'],
  methods: {
    selectItem(itemId) {
      this.$emit('input', itemId)
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-back {
  margin-right: 8px;
  font-weight: 700;
  cursor: pointer;
}
.list-group-item {
  border: 0;
  border-bottom: 1px solid var(--light);
  background-color: transparent;
  text-align: left;
  cursor: pointer;

  &:hover {
    background-color: var(--light);
  }
  &:first-child {
    cursor: default;
    background-color: transparent;
  }
  &:last-child {
    border-bottom: 0;
  }
}
</style>
