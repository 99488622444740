<template>
  <div :class="{ 'force-show-placeholder': selectedIndexes.length }">
    <popper ref="popperRef" class="action-popper" trigger="click" :options="popperOptions">
      <div class="popper">
        <div class="p-3">
          <div class="row mt-0">
            <div class="col">
              <h6 class="header-pretitle mb--4 mt-3 text-left">Answer Filters</h6>
            </div>
            <div class="col text-right">
              <button @click="reset()" class="btn btn-outline-secondary btn-sm mb--2">Reset</button>
            </div>
          </div>
          <hr class="mb-4" />

          <!-- mid section -->
          <div class="text-left">
            <button
              class="btn btn-white dropdown-toggle rounded-0 action-dropdown"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              :disabled="selectedQuestion"
            >
              <span class="text-black" style="white-space: normal">{{
                dropDownPlaceholderText
              }}</span>
            </button>
            <div class="dropdown-menu">
              <a
                v-for="question in filteredQuestionList"
                :key="question._id"
                data-toggle="true"
                class="dropdown-item"
                style="width: 333px; white-space: normal"
                href="javascript:;"
                @click="selectQuestion(question)"
              >
                {{ question.question }}
              </a>
            </div>

            <div v-if="selectedQuestion" class="text-muted pt-3 px-4 border-top">
              <div class="mb-2">Select answer(s) to filter by:</div>
              <div v-for="(option, index) of questionOptions" :key="index">
                <button
                  class="btn btn-block text-left pl-4 mb-2"
                  :class="{
                    'btn-primary': selectedIndexes.indexOf(index) >= 0,
                    'btn-outline-secondary': selectedIndexes.indexOf(index) < 0,
                  }"
                  style="white-space: normal"
                  @click="toggleSelected(index)"
                >
                  <span v-if="selectedQuestion.type !== 'RATING_SCALE'">
                    {{ option }}
                  </span>
                  <img
                    :src="`/img/ratings/${index + 1}.png`"
                    :alt="`rating ${index} emoji`"
                    contain
                    height="20"
                    v-else
                  />
                </button>
              </div>
            </div>
          </div>

          <!-- footer -->
          <hr class="w-100 mt-4" />
          <div class="mt-4 mb-2">
            <button class="btn btn-outline-primary btn-sm" @click="applyFilters">
              Apply Filter
            </button>
          </div>
        </div>
      </div>

      <multiselect
        slot="reference"
        :style="{
          width: placeholderTag ? 'auto' : '122px',
        }"
        selectLabel
        :hideSelected="false"
        :close-on-select="false"
        :searchable="false"
        :showLabels="false"
        :options="[]"
        v-model="placeholderTag"
        @input="applyFilters"
        track-by="id"
        label="question"
      >
        <template slot="placeholder">
          <span class="text-secondary pb-3">
            <i class="fas fa-list"></i>
            <span class="mt-2"> Any Answer </span>
          </span>
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
          <span class="text-secondary pb-3">
            <span class="mt-2 bg-light text-dark rounded p-1"> {{ option | truncate(20) }} </span>
          </span>
        </template>
      </multiselect>
    </popper>
  </div>
</template>

<script>
import _ from 'lodash'
import Multiselect from 'vue-multiselect'

import Popper from 'vue-popperjs'
import { createNamespacedHelpers } from 'vuex'

const QuestionModule = createNamespacedHelpers('customQuestion')

export default {
  name: 'CQActionFilter',
  components: {
    Multiselect,
    Popper,
  },
  data: () => ({
    selectedQuestion: null,
    selectedIndexes: [],
    placeholderTag: '',
    popperOptions: {
      placement: 'bottom',
      modifiers: {
        offset: { offset: '0,6px' },
        flip: { enabled: false },
        gpuAcceleration: true,
      },
    },
  }),
  mounted() {
    const includeFollowUpQuestions = true
    this.getCustomQuestions(includeFollowUpQuestions)
  },
  computed: {
    ...QuestionModule.mapGetters(['getQuestions']),
    dropDownPlaceholderText() {
      return this.selectedQuestion ? this.selectedQuestion.question : 'Select Question'
    },
    filteredQuestionList() {
      return this.getQuestions.filter((question) => question.type !== 'SHORT_ANSWER')
    },
    questionOptions() {
      if (!this.selectedQuestion) return
      if (this.selectedQuestion.type !== 'RATING_SCALE') {
        return this.selectedQuestion.questionData.options || []
      } else {
        return ['1', '2', '3', '4', '5']
      }
    },
  },
  methods: {
    ...QuestionModule.mapActions(['getCustomQuestions']),
    selectQuestion(question) {
      this.selectedQuestion = question
    },
    toggleSelected(optionIndex) {
      const selectedIndex = this.selectedIndexes.indexOf(optionIndex)
      if (selectedIndex >= 0) {
        this.selectedIndexes.splice(selectedIndex, 1)
      } else {
        this.selectedIndexes.push(optionIndex)
      }
      this.selectedIndexes.sort()
    },
    reset() {
      this.selectedQuestion = null
      this.selectedIndexes = []
      this.placeholderTag = ''
      this.$emit('updateCQAnswers', null)
    },
    applyFilters() {
      if (!this.selectedQuestion || !this.selectedIndexes.length) return
      const type = this.selectedQuestion.type
      const filterData = {
        customQuestion: this.selectedQuestion._id,
        type: type,
        selections:
          type === 'RATING_SCALE' ? this.selectedIndexes.map((i) => i + 1) : this.selectedIndexes,
        behavior: 'includes',
      }
      this.$emit('updateCQAnswers', filterData)
      this.placeholderTag = this.selectedQuestion.question
    },
  },
}
</script>

<style scoped>
.selected {
  background-color: var(--primary) !important;
  color: white !important;
}
.btn.btn-outline-secondary {
  color: black !important;
}
.btn-outline-secondary:hover {
  background-color: white;
}
.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none;
}
</style>
