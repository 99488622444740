<template>
  <span class="text-secondary text-capitalize">
    <span v-if="sourceLabel && sourceIcon">
      <i class="text-secondary" :class="sourceIcon"></i> {{ sourceLabel }}
    </span>
    <span v-else>
      <i class="fe fe-x text-muted text-faded text-large"></i>
    </span>
  </span>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const SurveyModule = createNamespacedHelpers('survey')

export default {
  name: 'SurveySource',
  props: {
    source: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...SurveyModule.mapState(['SURVEY_SOURCES']),
    sourceLabel() {
      const formatSource = this.SURVEY_SOURCES[this.source]

      return formatSource ? formatSource : this.source
    },
    sourceIcon() {
      const globalIcon = {
        'qr-code': 'fas fa-qrcode',
        'qr-tent': 'fas fa-qrcode',
        'qr-card': 'fas fa-qrcode',
        kiosk: 'fas fa-tablet-alt',
        'text-to-win': 'far fa-comment',
        imported: 'fas fa-file-import',
        manual: 'fe fe-user',
        integration: 'fe fe-link',
        web: 'fas fa-globe',
        dsp: 'fas fa-truck',
        'first-party': 'fas fa-store',
        olo: 'fe fe-link',
        revel: 'fe fe-link',
        hungerRush: 'fe fe-link',
        grubhub: 'fe fe-link',
        lunchbox: 'fe fe-link',
      }[this.source]

      return globalIcon ? globalIcon : 'far fa-building'
    },
  },
}
</script>

<style scoped lang="scss"></style>
