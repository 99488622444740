<template>
  <span>
    <i v-if="!feedback" class="fe fe-x text-muted text-faded"></i>
    <i
      v-else
      v-b-popover.hover.right="feedback"
      :title="`${emoji} Feedback`"
      class="fe fe-check-circle text-success"
    ></i>
  </span>
</template>

<script>
export default {
  name: 'SurveyEmoti',
  props: ['score', 'feedback'],
  computed: {
    emoji() {
      const emojis = ['😡', '🙁', '😐', '🙂', '😍']
      return emojis[this.score - 1]
    },
  },
}
</script>
