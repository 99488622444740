<template>
  <div class="card col-12">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/no-customers.svg"
            alt="..."
            class="img-fluid mt--5 mb-4"
            style="max-width: 272px"
          />

          <!-- <div> -->
          <h2>We haven't received any customer data yet.</h2>

          <p class="text-muted">
            You'll need to install an {{ brandName }} survey tool to start tracking customers.
          </p>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomersOffstate',
  computed: {
    ...mapGetters('user', ['brandName']),
  },
}
</script>

<style scoped lang="scss"></style>
