var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive mb-0"},[_c('table',{staticClass:"table table-sm table-nowrap card-table",attrs:{"id":"customers-table"}},[_c('thead',[_c('tr',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),(_vm.loyaltyEnabled)?_c('th',[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:;"}},[_vm._v("Points")])]):_vm._e(),_c('th',{staticClass:"text-right"})])]),_c('tbody',{staticClass:"list"},[(_vm.customers.length == 0)?_c('tr',{staticClass:"table-header-message text-center"},[_c('td',{attrs:{"colspan":"9"}},[_vm._v("No customers found. Try adjusting your filters.")])]):_vm._e(),_vm._l((_vm.customers),function(customer){return _c('CustomerTableRow',{key:customer._id,attrs:{"light":_vm.light,"customer-data":customer}})}),(_vm.count > _vm.customers.length)?_c('tr',{staticClass:"table-header-message text-center"},[_c('td',{attrs:{"colspan":"9"}},[_c('button',{staticClass:"btn btn btn-sm btn-outline-primary",on:{"click":_vm.loadMore}},[_vm._v(" Load more customers ")])])]):_vm._e()],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('a',{staticClass:"text-muted text-center",attrs:{"href":"javascript:;"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:;"}},[_vm._v("Last Activity")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:;"}},[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"text-center"},[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:;"}},[_vm._v("Feedback")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"text-center"},[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:;"}},[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"text-center"},[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:;"}},[_vm._v("Phone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('a',{staticClass:"text-muted",attrs:{"href":"javascript:;"}},[_vm._v("Tags")])])
}]

export { render, staticRenderFns }