<template>
  <div>
    <div v-show="initialized">
      <CustomersOffstate v-if="customers.length === 0 && Object.keys(listFilters).length === 0" />
      <div class="card" v-show="customers.length > 0 || Object.keys(listFilters).length > 0">
        <CustomersTableFilters
          :limit="limit"
          :show-string-filter="showStringFilter"
          :require-marketing-opt-in="requireMarketingOptIn"
          :campaignSource="campaignSource"
          :show-customer-count="showCustomerCount"
          @loaded-customers="() => (this.customersInitialized = true)"
          @filtersInitialized="() => (this.filtersInitialized = true)"
        />
        <CustomersTableList :light="light" v-if="!selectIsSearching" />
        <b-skeleton-table :columns="5" :rows="7" class="text-center" v-else />
      </div>
    </div>
    <div v-if="!initialized">
      <b-skeleton animation="fade" width="100%" height="200px" />
      <b-skeleton-table
        :columns="5"
        :rows="10"
        class="text-center"
        :table-props="{ bordered: true, striped: true }"
      />
    </div>
    <WinbackSidebar />
  </div>
</template>

<script>
import WinbackSidebar from '@/components/Modals/WinbackSidebar'
import CustomersTableFilters from './CustomersTableFilters'
import CustomersTableList from './CustomersTableList'
import CustomersOffstate from './CustomersOffstate'
import { createNamespacedHelpers } from 'vuex'
const CustomerModule = createNamespacedHelpers('customer')

export default {
  name: 'CustomersTableCard',
  props: {
    campaignSource: {
      type: Boolean,
      default: false,
    },
    showCustomerCount: {
      type: Boolean,
      default: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 50,
    },
    showStringFilter: {
      type: Boolean,
      default: true,
    },
    requireMarketingOptIn: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CustomersTableFilters,
    CustomersOffstate,
    CustomersTableList,
    WinbackSidebar,
  },
  data: () => ({
    customersInitialized: false,
    filtersInitialized: false,
  }),
  computed: {
    ...CustomerModule.mapState(['customers', 'listFilters']),
    ...CustomerModule.mapGetters(['selectIsSearching']),
    initialized() {
      return this.customersInitialized && this.filtersInitialized
    },
  },
  methods: {
    ...CustomerModule.mapActions(['queryCustomers']),
  },
}
</script>
