<template>
  <tr>
    <td class="align-middle text-large">
      <SurveyEmoti :score="customer.survey && customer.survey.rating" />
    </td>
    <td class="align-middle text-muted">
      {{ latestCustomerInteractionDate | created }}
    </td>
    <td class="align-middle text-capitalize" @click="openWinbackSidebar(customer)">
      {{ customer.name || 'Valued customer' }}
    </td>
    <td class="text-large align-middle text-center">
      <SurveyFeedback
        :score="customer.survey && customer.survey.rating"
        :feedback="customer.survey && customer.survey.feedback"
      />
    </td>
    <td class="text-center text-large" v-b-tooltip.click.hover="customer.email">
      <CheckOrX :value="customer.email" />
    </td>
    <td class="text-center text-large" v-b-tooltip.click.hover :title="customer.phone | phone">
      <CheckOrX :value="customer.phone" />
    </td>
    <td class="align-middle">
      <CustomerTags :customerTags="tags" :customerId="customer._id" />
    </td>
    <td v-if="loyaltyEnabled" class="align-middle">
      {{ (customer.loyalty && customer.loyalty.points) || 0 }}
    </td>
    <td class="text-right align-middle">
      <button
        class="btn btn-sm btn-primary mt--2 mr-3"
        v-if="!light && selectIsPermitted('winbackReadAccess')"
        v-b-toggle.winback-sidebar
        @click="openWinbackSidebar(customer)"
      >
        <i class="fe fe-message-square"></i>
      </button>
      <div class="dropdown d-inline-block" v-if="!light">
        <a class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" href="#" role="button">
          <i class="fe fe-more-vertical"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <a @click="confirmDelete(customer)" class="dropdown-item" href="javascript:;">
            <span class="text-danger">Delete</span>
          </a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import CheckOrX from './CustomersTableUtil/CheckOrX'
import SurveyEmoti from './CustomersTableUtil/SurveyEmoti'
import SurveySource from './CustomersTableUtil/SurveySource'
import CustomerTags from './CustomersTableUtil/CustomerTags'
import SurveyFeedback from './CustomersTableUtil/SurveyFeedback'
import { createNamespacedHelpers } from 'vuex'
import { format as formatDate } from 'date-fns'
const CompanyModule = createNamespacedHelpers('company')
const CustomerModule = createNamespacedHelpers('customer')
const TimelineModule = createNamespacedHelpers('timeline')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'CustomerTableRow',
  props: ['customerData', 'light'],
  filters: {
    created(date) {
      return formatDate(date, 'MM/DD/YY hh:mm a')
    },
  },
  components: {
    CheckOrX,
    SurveyEmoti,
    SurveyFeedback,
    CustomerTags,
  },
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
    ...CompanyModule.mapGetters(['loyaltyEnabled', 'selectIsPermitted']),
    ...CustomerModule.mapState(['listFilters']),
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds', 'selectActiveLocations']),
    latestCustomerInteractionDate() {
      return this.customer.survey && this.customer.survey.updated
        ? this.customer.survey.updated
        : this.customer.updated
    },
    tags() {
      const tags = (this.customer.tags || {})[this.activeCompany._id] || []
      return tags.filter(Boolean)
    },
    customer() {
      const customer = { ...this.customerData }

      if (customer.surveyCache) {
        const { companies } = customer.surveyCache

        if (companies && companies[this.activeCompany._id]) {
          customer.survey = companies[this.activeCompany._id]
        }
      }

      if (customer.loyaltyCache) {
        const { companies } = customer.loyaltyCache

        if (companies && companies[this.activeCompany._id]) {
          customer.loyalty = companies[this.activeCompany._id]
        }
      }

      return customer
    },
  },
  methods: {
    ...CustomerModule.mapActions(['deleteCustomer']),
    ...TimelineModule.mapActions(['initializeTimeline']),
    ...ConversationModuleV2.mapActions(['setSpecificConversation']),
    async openWinbackSidebar(customer) {
      this.setSpecificConversation({ filters: { customer }, trace: 'CustomerTableRow' })
      trackEvent(this.$intercom, 'Customer_Page_Winback')
    },
    viewFeedback(feedback) {
      alert(feedback)
    },
    confirmDelete(customer) {
      if (confirm("Are you sure you'd like to delete this customer?")) {
        this.deleteCustomer(customer)
      }
    },
  },
}
</script>
