<template>
  <div v-if="initialized">
    <GroupingFilter
      :nonGroupMembers="selectActiveLocations"
      :disabledNonGroupMembers="forCampaign ? null : selectDisabledLocations"
      nonGroupMemberLabel="Locations"
      memberType="location"
      @update="groupingUpdate"
      :key="groupingRefreshKey"
      :init="{
        nonGroupMemberIds: this.filteredLocations,
        groupIds: null,
        disabledNonGroupMemberIds: null,
      }"
    />
  </div>
</template>

<script>
// This is a wrapper component to allow the grouping filter to work with campaign persistence
import { createNamespacedHelpers } from 'vuex'
import GroupingFilter from '@/components/Modules/Filters/GroupingFilter'

const LocationModule = createNamespacedHelpers('location')
const CampaignModule = createNamespacedHelpers('campaign')
const CompanyModule = createNamespacedHelpers('company')
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'CustomerGroupingFilter',
  props: {
    forCampaign: { type: Boolean, default: false },
  },
  components: {
    GroupingFilter,
  },
  data: () => ({
    filteredLocations: null,
    initialized: false,
  }),
  async mounted() {
    await this.setLocations()
    this.initialized = true
  },
  computed: {
    ...LocationModule.mapGetters(['selectActiveLocations', 'selectDisabledLocations']),
    ...CampaignModule.mapGetters(['selectCampaign']),
    ...CompanyModule.mapGetters(['activeCompany']),
    ...GroupModule.mapGetters(['selectGroups']),

    groupingRefreshKey() {
      return (
        this.activeCompany._id +
        this.selectActiveLocations?.map((c) => c._id).join('') +
        this.selectGroups.map((g) => g._id).join('')
      )
    },
  },
  methods: {
    async setLocations() {
      if (this.selectCampaign?._id && this.selectCampaign?.filters?.locations?.length) {
        this.filteredLocations = this.selectCampaign.filters.locations
      }
    },
    groupingUpdate(val) {
      this.$emit('update', val)
    },
  },
}
</script>
