<template>
  <div class="d-flex align-items-center justify-content-between container">
    <div class="select" ref="select">
      <MultiSelect
        taggable
        multiple
        hideCaret
        reposition
        hideSelected
        label="name"
        track-by="name"
        v-model="value"
        :id="customerId"
        :options="options"
        :show-labels="false"
        openDirection="below"
        tag-placeholder="Add"
        placeholder="Add tag"
        :showNoResults="false"
        :internal-search="true"
        @tag="addNewTag"
        @select="addTagToCustomer"
        @remove="removeTagFromCustomer"
        :loading="loadings[customerId]"
        class="flex-grow-1"
      >
        <template #tag="{ option, remove }"
          ><span :class="['multiselect__tag', !option.company && 'global-tag']"
            ><span>{{ option.name }}</span>
            <i
              v-if="option.company"
              role="button"
              aria-hidden="true"
              tabindex="1"
              class="multiselect__tag-icon"
              @mousedown.prevent="remove(option)"
              @keypress.enter.prevent="remove(option)"
            ></i></span
        ></template>
      </MultiSelect>
    </div>
    <a
      v-if="hidedLength"
      @click="scrollToEnd"
      role="button"
      href="#!"
      class="ml-2 text-small text-muted"
      >+{{ hidedLength }} more</a
    >
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MultiSelect from '@/components/Common/MultiSelect'

const tagModule = createNamespacedHelpers('tag')
const customerModule = createNamespacedHelpers('customer')

export default {
  name: 'CustomerTags',

  components: {
    MultiSelect,
  },

  props: {
    customerId: {
      type: String,
      required: true,
    },
    customerTags: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    options: [],
    value: [],
    loadings: {},
  }),

  computed: {
    ...tagModule.mapGetters(['externalTags', 'tagsMap']),
    hidedLength() {
      return this.value.length > 5 ? this.value.slice(3).length : 0
    },
  },

  watch: {
    externalTags: {
      immediate: true,
      handler(currentTags) {
        this.options = currentTags || []
      },
    },

    customerTags: {
      immediate: true,
      handler(currentTags) {
        this.cacheCurrentTag(currentTags)
        this.value = currentTags.map((t) => this.tagsMap[t]).filter(Boolean)
      },
    },
  },

  methods: {
    ...tagModule.mapActions(['addTag', 'cacheCurrentTag']),
    ...customerModule.mapActions(['tagCustomer', 'untagCustomer']),

    async addNewTag(tagName) {
      this.$set(this.loadings, this.customerId, true)

      try {
        const { _id: tagId, name } = await this.addTag(tagName)
        await this.tagCustomer({ customerId: this.customerId, tagId })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: `failed to add ${tagName} tag`,
        })
      } finally {
        this.$set(this.loadings, this.customerId, false)
      }
    },

    async addTagToCustomer({ _id: tagId }) {
      this.$set(this.loadings, this.customerId, true)

      try {
        await this.tagCustomer({ customerId: this.customerId, tagId })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: `failed to tag customer`,
        })
      } finally {
        this.$set(this.loadings, this.customerId, false)
      }
    },

    async removeTagFromCustomer({ _id: tagId, company }) {
      if (!company) return

      this.$set(this.loadings, this.customerId, true)

      try {
        await this.untagCustomer({ customerId: this.customerId, tagId })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: `failed to remove tag from customer`,
        })
      } finally {
        this.$set(this.loadings, this.customerId, false)
      }
    },

    scrollToEnd() {
      this.$refs.select.scrollLeft += 150
    },
  },
}
</script>

<style scoped lang="scss">
.select {
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.select::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.global-tag {
  padding-right: 10px;
}
::v-deep .multiselect {
  .multiselect__tags {
    border: none !important;
    padding-left: 0;
    padding-right: 0;
    width: 200px;
  }
  .multiselect__content-wrapper {
    border-top: 1px solid #e8e8e8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .multiselect__option {
    &::first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
