<template>
  <div class="table-responsive mb-0">
    <table id="customers-table" class="table table-sm table-nowrap card-table">
      <thead>
        <tr>
          <!-- <th>
            <div class="custom-control custom-checkbox table-checkbox">
              <input class="custom-control-input" id="ordersSelectAll" name="ordersSelect" type="checkbox"> <label class="custom-control-label" for="ordersSelectAll">&nbsp;</label>
            </div>
          </th>-->
          <th>
            <a class="text-muted text-center" href="javascript:;"></a>
          </th>
          <th>
            <a class="text-muted" href="javascript:;">Last Activity</a>
          </th>
          <th>
            <a class="text-muted" href="javascript:;">Name</a>
          </th>
          <th class="text-center">
            <a class="text-muted" href="javascript:;">Feedback</a>
          </th>
          <th class="text-center">
            <a class="text-muted" href="javascript:;">Email</a>
          </th>
          <th class="text-center">
            <a class="text-muted" href="javascript:;">Phone</a>
          </th>
          <th>
            <a class="text-muted" href="javascript:;">Tags</a>
          </th>
          <th v-if="loyaltyEnabled">
            <a class="text-muted" href="javascript:;">Points</a>
          </th>
          <th class="text-right"></th>
        </tr>
      </thead>
      <tbody class="list">
        <!-- <tr class="table-header-message selected-all" style="display: none;">
          <td colspan="8"><span>Selected these 100. <a href="#">Would you like to select all 5,951 customers?</a></span></td>
        </tr>-->
        <tr class="table-header-message text-center" v-if="customers.length == 0">
          <td colspan="9">No customers found. Try adjusting your filters.</td>
        </tr>
        <CustomerTableRow
          :light="light"
          v-for="customer in customers"
          :key="customer._id"
          :customer-data="customer"
        />
        <tr v-if="count > customers.length" class="table-header-message text-center">
          <td colspan="9">
            <button @click="loadMore" class="btn btn btn-sm btn-outline-primary">
              Load more customers
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import CustomerTableRow from './CustomerTableRow'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'CustomersTableList',
  props: ['light'],
  components: {
    CustomerTableRow,
  },
  computed: {
    ...mapState('customer', ['customers', 'count']),
    ...mapGetters('company', ['loyaltyEnabled']),
  },
  methods: {
    ...mapActions('tag', ['getTags']),
    ...mapActions('customer', ['queryCustomers']),
    async loadMore() {
      try {
        await Promise.all([this.queryCustomers({ loadMore: true }), this.getTags()])
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>
